import Bridge, { SetupRequest } from "./base";
import MessageSerializer from "../util/messageSerializer";

type MessageID = number;
interface Message {
  id: MessageID;
  method?: string;
  payload: any;
  version: string;
  type: string;
}

export interface Options {
  targetWindow: Window;
  targetOrigin: string;
}

export const LAYERS_PORTAL_INNER_LOCATION_BASE_KEY =
  "__layers_portal_inner_location_base__";
export const LAYERS_PORTAL_LOCATION_KEY = "__layers_portal_location__";

export class IFrameBridge extends Bridge {
  private pendingMessages: {};
  private targetWindow: any;
  private targetOrigin: any;
  private version: any;
  private _bindedEventHandler: EventListener;

  private layersPortalLocation?: string;
  private layersPortalInnerLocationBase?: string;

  private lastOrigin?: string;

  constructor(options: Options) {
    super();

    this.pendingMessages = {};

    this.targetWindow = options.targetWindow;
    this.targetOrigin = options.targetOrigin;

    this.version = "__LAYERS_SDK_VERSION__";

    this._bindedEventHandler = this._eventHandler.bind(this);
    window.addEventListener("message", this._bindedEventHandler, false);
  }

  getPlatform(): string {
    return "iframe";
  }

  getLastOrigin(): string {
    return this.lastOrigin
  }

  async setup(params: SetupRequest) {
    if (window === this.targetWindow) {
      throw new Error("Target must be a different Window");
    }
    const result = await super.setup(params);

    this.layersPortalLocation = result.payload?.layersPortalLocation;
    this.layersPortalInnerLocationBase =
      result.payload?.layersPortalInnerLocationBase;
    window.addEventListener("focus", () => this.setLocalStorage());
    this.setLocalStorage();

    return result;
  }

  private setLocalStorage() {
    try {
      if (this.layersPortalLocation) {
        localStorage[LAYERS_PORTAL_LOCATION_KEY] = this.layersPortalLocation;
      }
      if (this.layersPortalInnerLocationBase) {
        localStorage[
          LAYERS_PORTAL_INNER_LOCATION_BASE_KEY
        ] = this.layersPortalInnerLocationBase;
      }
    } catch (error) {
      // Might fail if LocalStorage is disabled
    }
  }

  destroy() {
    if (this._bindedEventHandler) {
      window.removeEventListener("message", this._bindedEventHandler, false);
      this._bindedEventHandler = null;
    }

    this.ready = false;
  }

  send(method: string, payload?: any, timeout = 30000) {
    const promise = new Promise((resolve, reject) => {
      const id = generateId();
      const timeoutId = setTimeout(() => {
        reject(new Error(`Message ${id} timed out!`));
      }, timeout);
      this.pendingMessages[id] = {
        resolve: resolve,
        reject: reject,
        timeoutId: timeoutId,
      };

      const message: Message = {
        id: id,
        method: method,
        payload: payload,
        version: this.version,
        type: "request",
      };
      try {
        this._postMessage(this.targetWindow, message, this.targetOrigin);
      } catch (error) {
        return reject(error);
      }
    });
    return promise;
  }

  private dispatch(method: string, payload: any) {
    if (!this.requestHandlers.has(method)) return;

    const handler = this.requestHandlers.get(method);
    return handler(payload);
  }

  private _postMessage(
    _window: Window,
    message: Message,
    targetOrigin: string
  ) {
    const serializedMessage = MessageSerializer.serialize(message);
    _window.postMessage(serializedMessage, targetOrigin);
  }

  private _eventHandler(event: MessageEvent) {
    if (event.source !== this.targetWindow) {
      return;
    }

    this.lastOrigin = event.origin;

    let message: Message;
    try {
      message = MessageSerializer.deserialize(event.data);
    } catch (error) {
      return; // Ignore malformed messages, since it probably shouldn't be handled by SDK
    }

    if (!message.id) {
      this.dispatch("error", new Error("Message received without id!"));
      return;
    }

    switch (message.type) {
      case "response":
        this._handleResponseMessage(message);
        return;
      case "error":
        this._handleErrorMessage(message);
        return;
      case "request":
        this._handleRequestMessage(message, event.source);
        return;
      default:
        this.dispatch(
          "error",
          new Error(`Message received with unknown type "${message.type}"!`)
        );
    }
  }

  private _handleResponseMessage(message: Message) {
    const id = message.id;
    const payload = message.payload;

    if (!(id in this.pendingMessages)) {
      return;
    }

    const pendingMessage = this.pendingMessages[id];
    const resolve = pendingMessage.resolve;
    const timeoutId = pendingMessage.timeoutId;

    clearTimeout(timeoutId);
    delete this.pendingMessages[id];

    resolve(payload);
  }

  private _handleErrorMessage(message: Message) {
    const id = message.id;
    const error = new Error(message.payload);

    if (!(id in this.pendingMessages)) {
      return;
    }

    const pendingMessage = this.pendingMessages[id];
    const reject = pendingMessage.reject;
    const timeoutId = pendingMessage.timeoutId;

    clearTimeout(timeoutId);
    delete this.pendingMessages[id];

    reject(error);
  }

  private _handleRequestMessage(message: Message, sourceWindow: any) {
    const id = message.id;
    const method = message.method;
    const payload = message.payload;

    const promise = this.dispatch(method, payload);
    Promise.resolve(promise)
      .then((res) => {
        this._postMessage(
          sourceWindow,
          { id: id, payload: res, version: this.version, type: "response" },
          "*"
        );
      })
      .catch((error) => {
        this._postMessage(
          sourceWindow,
          {
            id: id,
            payload: error.message,
            version: this.version,
            type: "error",
          },
          "*"
        );
      });
  }
}

function generateId(): MessageID {
  return ~~(Math.random() * (1 << 30));
}

export default IFrameBridge;
