import { LayersPortalOptions, ParamsType } from "../app";
export interface SetupRequest {
  options: LayersPortalOptions;
  url: string;
  state: any;
  title?: string;
}
export interface SetupResponse {
  bridgeConnected: boolean;
  platform?: string;
  location?: string;
  baseLocation?: string;
  payload?: any;

  session: string | null;
  userId: string | null;
  communityId: string | null;
  accountId: string | null;
  params: ParamsType;
  preferredLanguages: string[] | null;
  preferredTimezone: string | null;
}

export default abstract class Bridge {
  ready: boolean;

  protected requestHandlers: Map<string, (params: any) => any>;
  protected options?: LayersPortalOptions;

  constructor() {
    this.requestHandlers = new Map();
    this.ready = false;
  }

  addRequestHandler(method: string, handler: (params: any) => any) {
    this.requestHandlers.set(method, handler);
  }

  abstract send(method: string, payload?: any, timeout?: number): Promise<any>;

  abstract getPlatform(): string;

  async download(data: { url: string; filename: string }) {
    return await this.send("download", data);
  }

  async requestStoreReview() {
    return await this.send("requestStoreReview");
  }

  startGeolocation(payload: { interval: number; duration: number }) {
    this.send("startGeolocation", payload);
  }

  stopGeolocation() {
    this.send("stopGeolocation");
  }

  async setup(params: SetupRequest): Promise<SetupResponse> {
    if (this.ready) {
      throw new Error("LayersSDK already set up!");
    }

    this.options = params.options;
    const response = await this.send("setup", params);

    if (response.success) {
      this.ready = true;
    } else {
      throw new Error("Handshake failed!");
    }

    return {
      bridgeConnected: true,
      platform: this.getPlatform(),
      location: response.location,
      baseLocation: response.baseLocation,
      payload: response.payload,

      session: response.session,
      userId: response.userId,
      communityId: response.communityId,
      accountId: response.accountId,
      params: response.params,
      preferredLanguages: response.preferredLanguages,
      preferredTimezone: response.preferredTimezone,
    };
  }
}
