import HistoryWatcher from "./util/HistoryWatcher";
import TitleWatcher from "./util/TitleWatcher";
import { createBridge } from "./bridge";
import Bridge, { SetupResponse } from "./bridge/base";
import createEventTarget from "./util/createEventTarget";

export interface LayersPortalOptions {
  // ID do App
  appId: string;

  // Indica se a página só pode ser aberta dentro do Layers
  // Caso não esteja, redireciona para o Layers ID, id.layers.digital
  insidePortalOnly: boolean;
}
export type ParamsType = {
  [key: string]: unknown;
};

export interface LayersPortalSDK {
  (method: string, payload?: any): Promise<any>;

  on(eventName: string, handler: (payload: any) => void): void;
  ready: boolean;
  readyPromise: Promise<SetupResponse>;
  connected: boolean;
  connectedPromise: Promise<void>;
  platform: string | null;

  session: string | null;
  userId: string | null;
  communityId: string | null;
  accountId: string | null;
  params: ParamsType;
  preferredLanguages: string[] | null;
  preferredTimezone: string | null;
}

function buildLayersSdk(): LayersPortalSDK {
  let parentBridge: Bridge;
  const eventTarget = createEventTarget();
  const historyWatcher: HistoryWatcher = new HistoryWatcher();
  const titleWatcher: TitleWatcher = new TitleWatcher();

  interface RealLayersSDK extends LayersPortalSDK {
    setupResult: SetupResponse;
    options?: LayersPortalOptions;

    readyPromise: Promise<SetupResponse>;
    readyPromiseResolve: Function;
    readyPromiseReject: Function;

    connectedPromise: Promise<void>;
    connectedPromiseResolve: Function;
    connectedPromiseReject: Function;
  }

  const METHODS: {
    [methodName: string]: (this: RealLayersSDK, ...params: any) => any;
  } = {
    async setup(options: LayersPortalOptions) {
      if (this.ready) {
        throw new Error("LayersPortalSDK already set up!");
      }

      this.options = options;

      parentBridge = createBridge();
      parentBridge.addRequestHandler("ping", () => {
        return "pong";
      });

      try {
        this.setupResult = await parentBridge.setup({
          options: options,
          url: window.location.href,
          state: history?.state,
          title: titleWatcher.getTitle(),
        });
      } catch (error) {
        if ((this as any).readyPromiseReject) {
          (this as any).readyPromiseReject(error);
        }
        if ((this as any).connectedPromiseReject) {
          (this as any).connectedPromiseReject(error);
        }
        return;
      }

      this.ready = true;
      this.platform = this.setupResult.platform;

      eventTarget.dispatchEvent(
        new CustomEvent("ready", {
          detail: this.setupResult,
        })
      );

      if ((this as any).readyPromiseResolve) {
        (this as any).readyPromiseResolve(this.setupResult);
      }

      if (!this.setupResult.bridgeConnected) {
        return;
      }

      this.connected = true;
      this.session = this.setupResult.session;
      this.accountId = this.setupResult.accountId;
      this.userId = this.setupResult.userId;
      this.communityId = this.setupResult.communityId;
      this.params = this.setupResult.params;
      this.preferredLanguages = this.setupResult.preferredLanguages;
      this.preferredTimezone = this.setupResult.preferredTimezone;

      eventTarget.dispatchEvent(
        new CustomEvent("connected", {
          detail: this.setupResult,
        })
      );

      if ((this as any).connectedPromiseResolve) {
        (this as any).connectedPromiseResolve(this.setupResult);
      }

      historyWatcher.addListener((params) => {
        this("update", params);
      });
      titleWatcher.addListener((title) => {
        this("update", { title });
      });

      historyWatcher.updateHistory();
      titleWatcher.updateTitle();
    },

    ping() {
      return parentBridge.send("ping");
    },

    getAccountToken() {
      return parentBridge.send("getAccountToken");
    },

    getCommunity() {
      return parentBridge.send("getCommunity");
    },

    ready() {
      return parentBridge.send("ready");
    },

    update(params: { url?: string; state?: any; title?: string }) {
      return parentBridge.send("update", params);
    },

    async download(data: { url: string; filename: string }) {
      return await parentBridge.download(data);
    },

    async requestStoreReview() {
      return await parentBridge.requestStoreReview();
    },

    close(payload?: any) {
      parentBridge.send("close", payload);

      try {
        window.close();
      } catch (e) {}
    },

    go(path: string) {
      return parentBridge.send("go", path);
    },

    openPortal(payload: { alias: string; path?: string; params?: ParamsType }) {
      return parentBridge.send("openPortal", {
        alias: payload.alias,
        path: payload.path,
        params: payload.params,
      });
    },

    notify(payload: any) {
      return parentBridge.send("notify", payload);
    },

    scanBarcode(payload: any) {
      return parentBridge.send("scanBarcode", payload);
    },

    ["layers-id:callback"](params: any) {
      return parentBridge.send("layers-id:callback", params);
    },

    startGeolocation(payload: { interval: number; duration: number }) {
      return parentBridge.send("startGeolocation", payload);
    },

    stopGeolocation() {
      return parentBridge.send("stopGeolocation");
    },
  };

  const _Layers: RealLayersSDK = async (methodName: string, payload?: any) => {
    const method = METHODS[methodName];
    if (!method) {
      throw new Error(
        `Method ${methodName} does not exists on LayersPortal SDK.`
      );
    }

    return await method.bind(_Layers)(payload);
  };

  _Layers.ready = false;
  _Layers.connected = false;
  _Layers.setupResult = null;
  _Layers.platform = this;

  _Layers.session = null;
  _Layers.userId = null;
  _Layers.communityId = null;
  _Layers.accountId = null;
  _Layers.params = {};
  _Layers.preferredLanguages = null;
  _Layers.preferredTimezone = null;

  _Layers.on = (eventName: string, handler: (payload: any) => void) => {
    eventTarget.addEventListener(eventName, (event: CustomEvent) => {
      handler(event.detail);
    });
  };

  // Get commands queued before SDK was loaded
  const commandQueue: [
    (resolve: any) => any,
    (reject: any) => any,
    string,
    any
  ][] = (window.LayersPortal as any)?.q;

  // Consume queued commands now that SDK is ready
  if (commandQueue) {
    for (const command of commandQueue) {
      const [resolve, reject, method, payload] = command;
      _Layers(method, payload).then(resolve).catch(reject);
    }
  }

  // Get event handlers set before SDK was loaded
  const eventHandlers: { [eventName: string]: ((payload: any) => void)[] } = (window.LayersPortal as any)?.eh;
  if (eventHandlers) {
    for (const eventName in eventHandlers) {
      for (const handler of eventHandlers[eventName]) {
        _Layers.on(eventName, handler);
      }
    }
  }

  _Layers.readyPromise = (window.LayersPortal as any)?.readyPromise;
  if (_Layers.readyPromise != null) {
    _Layers.readyPromiseResolve = ((
      window.LayersPortal
    ) as any)?.readyPromiseResolve;
    _Layers.readyPromiseReject = (window.LayersPortal as any)?.readyPromiseReject;
  } else {
    _Layers.readyPromise = new Promise((resolve, reject) => {
      _Layers.readyPromiseResolve = resolve;
      _Layers.readyPromiseReject = reject;
    });
  }
  _Layers.connectedPromise = (window.LayersPortal as any)?.connectedPromise;
  if (_Layers.connectedPromise != null) {
    _Layers.connectedPromiseResolve = ((
      window.LayersPortal
    ) as any)?.connectedPromiseResolve;
    _Layers.connectedPromiseReject = ((
      window.LayersPortal
    ) as any)?.connectedPromiseReject;
  } else {
    _Layers.connectedPromise = new Promise((resolve, reject) => {
      _Layers.connectedPromiseResolve = resolve;
      _Layers.connectedPromiseReject = reject;
    });
  }

  return _Layers;
}

declare global {
  interface Window {
    LayersPortal: LayersPortalSDK;
    LayersPortalOptions: LayersPortalOptions;
  }
}

// Expose "LayersPortal" globally
window.LayersPortal = buildLayersSdk();
if (window.LayersPortalOptions) {
  window.LayersPortal("setup", window.LayersPortalOptions);
}
